import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Grid,
  Typography,
  Modal,
  IconButton,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { LocationOn, OpenInNew, Close, Edit } from "@mui/icons-material";
import MuiPhoneInput from "../../general/MuiPhoneInput";
import {
  getDetailedInfluencerInformation,
  editInfluencerDetails,
} from "../../../services/influencer/Influencer";
import { snapshotHeaderIcons2, textColor } from "../snapshot";
import stringAvatar from "../../../utils/avatar";
import configs from "../../../config/main.json";
import { useDispatch } from "react-redux";
import LocationInput from "../../general/LocationInput";

const phoneIcon = `${configs.BASE_IMAGE}Vector.svg`;
const mailIcon = `${configs.BASE_IMAGE}email.svg`;
const locationIcon = `${configs.BASE_IMAGE}location.svg`;
const warningIcon = `${configs.BASE_IMAGE}Warning.svg`;
const ageIcon = `${configs.BASE_IMAGE}age.svg`;
const genderIcon = `${configs.BASE_IMAGE}Gender.svg`;

const genderData = ["Male", "Female", "Other", "Decline to self identify"];
const ageData = [
  { value: "13-17", text: "13-17" },
  { value: "18-24", text: "18-24" },
  { value: "25-34", text: "25-34" },
  { value: "35-44", text: "35-44" },
  { value: "45-54", text: "45-54" },
  { value: "55-64", text: "55-64" },
  { value: "65+", text: "65+" },
];

const SelectField = ({ label, options, value, onChange, disabled = false }) => (
  <FormControl size="small" sx={{ minWidth: 150, marginRight: "10px" }}>
    <InputLabel id={`${label}-select-label`}>Select {label}</InputLabel>
    <Select
      labelId={`${label}-select-label`}
      value={value || ""}
      label={`Select ${label}`}
      onChange={onChange}
      disabled={disabled}
    >
      {options.map((option) => (
        <MenuItem key={option.value || option} value={option.value || option}>
          {option.text || option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const removeEmptyStrings = (arr) => {
  if (!Array.isArray(arr)) return [];
  return arr.filter((item) => typeof item === "string" && item.trim() !== "");
};

const arraysEqual = (arr1, arr2) => {
  if (!arr1 && !arr2) return true;
  if (!arr1 || !arr2) return false;
  if (arr1.length !== arr2.length) return false;
  return arr1.every((val, i) => val === arr2[i]);
};

const prepareFieldUpdates = (typeOfRequest, originalArr, finalArr, id) => {
  const updates = [];
  const length = Math.max(originalArr.length, finalArr.length);

  for (let i = 0; i < length; i++) {
    const ov = (originalArr[i] || "").trim();
    const nv = (finalArr[i] || "").trim();

    if (ov === nv) continue; // no change

    // If adding a new value
    if (!ov && nv) {
      if (typeOfRequest === "phone" && nv && !nv.includes("-")) {
        alert(`Phone number "${nv}" must include dial code "-"`);
        return [];
      }
      updates.push({ typeOfRequest, value: nv, id, edit: false, originalValue: "" });
      continue;
    }

    // If editing an existing value
    if (ov && nv && ov !== nv) {
      if (typeOfRequest === "phone" && nv && !nv.includes("-")) {
        alert(`Phone number "${nv}" must include dial code "-"`);
        return [];
      }
      updates.push({ typeOfRequest, value: nv, id, edit: true, originalValue: ov });
      continue;
    }
    // No removal logic here
  }

  return updates;
};

const ContactCard = ({
  platform,
  influencerAccount,
  viewContactDialog,
  closeContactDialog,
  user,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [influencerInformation, setInfluencerInformation] = useState({
    platforms: [],
    campaignData: {
      activeCampaigns: 0,
      pastCampaigns: 0,
      contractsSigned: 0,
    },
    createdAt: "",
    names: [],
    primaryContactNumber: {},
    primaryAddress: {},
    contacts: [],
    emails: [],
    genders: [],
    ageRanges: [],
    locations: [],
  });
  const [error, setError] = useState(null);

  // Original arrays for comparison
  const [originalContacts, setOriginalContacts] = useState([]);
  const [originalEmails, setOriginalEmails] = useState([]);
  const [originalGenders, setOriginalGenders] = useState([]);
  const [originalAges, setOriginalAges] = useState([]);
  const [originalPrimaryLocation, setOriginalPrimaryLocation] = useState("");
  const [originalLocations, setOriginalLocations] = useState([]);

  // Editable fields
  const [editContacts, setEditContacts] = useState([]);
  const [editEmails, setEditEmails] = useState([]);
  const [editGenders, setEditGenders] = useState([]);
  const [editAgeRanges, setEditAgeRanges] = useState([]);
  const [editPrimaryLocation, setEditPrimaryLocation] = useState("");
  const [editLocations, setEditLocations] = useState([]);

  useEffect(() => {
    if (influencerAccount && influencerAccount.id && viewContactDialog) {
      fetchInfluencerInformation();
    }
  }, [influencerAccount, viewContactDialog]);

  const fetchInfluencerInformation = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await getDetailedInfluencerInformation(
        influencerAccount.id,
        user,
        dispatch
      );
      const contacts = Array.isArray(data.contacts) ? data.contacts : [];
      const genders = Array.isArray(data.genders) ? data.genders : [];
      const ageRanges = Array.isArray(data.ageRanges) ? data.ageRanges : [];
      const emails = Array.isArray(data.emails) ? data.emails : [];
      const names = Array.isArray(data.names) ? data.names : [];
      const locations = Array.isArray(data.locations) ? data.locations : [];

      const cleanedContacts = removeEmptyStrings(contacts);
      const cleanedEmails = removeEmptyStrings(emails);
      const cleanedGenders = removeEmptyStrings(genders);
      const cleanedAges = removeEmptyStrings(ageRanges);
      const primaryLoc = data.primaryAddress?.value || "";
      const otherKnownLocations = removeEmptyStrings(locations).filter(
        (loc) => loc !== primaryLoc
      );

      setInfluencerInformation({
        ...data,
        platforms: Array.isArray(data.platforms) ? data.platforms : [],
        campaignData: {
          activeCampaigns: data.campaignData?.activeCampaigns || 0,
          pastCampaigns: data.campaignData?.pastCampaigns || 0,
          contractsSigned: data.campaignData?.contractsSigned || 0,
        },
        createdAt: data.createdAt || "",
        names,
        primaryContactNumber: data.primaryContactNumber || {},
        primaryAddress: data.primaryAddress || {},
        contacts,
        emails,
        genders,
        ageRanges,
        locations,
      });

      // Store original arrays
      setOriginalContacts(cleanedContacts);
      setOriginalEmails(cleanedEmails);
      setOriginalGenders(cleanedGenders);
      setOriginalAges(cleanedAges);
      setOriginalPrimaryLocation(primaryLoc);
      setOriginalLocations(otherKnownLocations);

      // Initialize edit fields
      setEditContacts([...cleanedContacts]);
      setEditEmails([...cleanedEmails]);
      setEditGenders([...cleanedGenders]);
      setEditAgeRanges([...cleanedAges]);
      setEditPrimaryLocation(primaryLoc);
      setEditLocations([...otherKnownLocations]);
    } catch (err) {
      console.error("Error fetching influencer information:", err);
      setError("Failed to load influencer information.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    if (isEditMode) {
      if (window.confirm("Discard changes?")) {
        setIsEditMode(false);
        closeContactDialog(false);
      }
    } else {
      closeContactDialog(false);
    }
  };

  const handleEditToggle = () => {
    setIsEditMode(true);
  };

  const handleCancelEdit = () => {
    fetchInfluencerInformation();
    setIsEditMode(false);
  };

  const handleSaveChanges = async () => {
    const id = influencerAccount.id;
    let updates = [];

    // Emails
    if (!arraysEqual(editEmails, originalEmails)) {
      const emailUpdates = prepareFieldUpdates("email", originalEmails, editEmails, id);
      updates = updates.concat(emailUpdates);
    }

    // Phones
    if (!arraysEqual(editContacts, originalContacts)) {
      const phoneUpdates = prepareFieldUpdates("phone", originalContacts, editContacts, id);
      updates = updates.concat(phoneUpdates);
    }

    // Genders
    if (!arraysEqual(editGenders, originalGenders)) {
      const genderUpdates = prepareFieldUpdates("gender", originalGenders, editGenders, id);
      updates = updates.concat(genderUpdates);
    }

    // Age Ranges
    if (!arraysEqual(editAgeRanges, originalAges)) {
      const ageUpdates = prepareFieldUpdates("age_range", originalAges, editAgeRanges, id);
      updates = updates.concat(ageUpdates);
    }

    // Locations (primary + others)
    const finalLocations = [
      editPrimaryLocation.trim(),
      ...editLocations.map((l) => l.trim()),
    ].filter((l) => l);
    const originalFullLocations = [
      originalPrimaryLocation.trim(),
      ...originalLocations.map((l) => l.trim()),
    ].filter((l) => l);

    if (!arraysEqual(finalLocations, originalFullLocations)) {
      const locationUpdates = prepareFieldUpdates(
        "address",
        originalFullLocations,
        finalLocations,
        id
      );
      updates = updates.concat(locationUpdates);
    }

    // Remove duplicates if any
    updates = updates.filter(
      (update, index, self) =>
        index ===
        self.findIndex(
          (u) =>
            u.typeOfRequest === update.typeOfRequest &&
            u.value === update.value &&
            u.originalValue === update.originalValue &&
            u.edit === update.edit
        )
    );

    if (updates.length === 0) {
      alert("No changes detected.");
      return;
    }

    try {
      await Promise.all(
        updates.map((update) => editInfluencerDetails(update, user, dispatch))
      );
      alert("Changes saved successfully.");
      setIsEditMode(false);
      fetchInfluencerInformation();
    } catch (err) {
      console.error("Error saving changes:", err);
      alert("Failed to save changes. Please try again.");
    }
  };

  // ==========================
  // Phone helpers
  // ==========================
  const handlePhoneInputChange = (value, country, index) => {
    let dialCode = country.dialCode;
    let cleanValue = value.replace(/[^0-9]/g, "");
    if (cleanValue.startsWith(dialCode)) {
      cleanValue = cleanValue.substring(dialCode.length);
    }
    const updatedPhones = [...editContacts];
    updatedPhones[index] = `${dialCode}-${cleanValue}`;
    setEditContacts(updatedPhones);
  };
  const getMuiPhoneValue = (phone) => {
    if (phone.includes("-")) {
      return `+${phone}`;
    }
    return `+1${phone}`;
  };
  const getPhoneDialAndNumber = (phone) => {
    if (phone.includes("-")) {
      const [dial, num] = phone.split("-");
      return { dialCode: dial, number: num };
    }
    return { dialCode: "1", number: phone };
  };

  const formatDate = (isoDate) => {
    if (!isoDate) return "-";
    const date = new Date(isoDate);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleMakePrimaryContactChange = (phone) => {
    if (window.confirm(`Are you sure you want to set "${phone}" as the primary contact number?`)) {
      const requestBody = {
        typeOfRequest: "phone",
        value: phone,
        id: influencerAccount.id,
        action: "makePrimary",
      };
      editInfluencerDetails(requestBody, user, dispatch)
        .then(() => {
          alert("Primary contact number updated successfully.");
          fetchInfluencerInformation();
        })
        .catch((error) => {
          console.error("Error making phone primary:", error);
          alert("Failed to set primary contact number. Please try again.");
        });
    }
  };

  const handleMakePrimaryEmail = (email) => {
    if (window.confirm(`Are you sure you want to set "${email}" as the primary email?`)) {
      const requestBody = {
        typeOfRequest: "email",
        value: email,
        id: influencerAccount.id,
        action: "makePrimary",
      };
      editInfluencerDetails(requestBody, user, dispatch)
        .then(() => {
          alert("Primary email updated successfully.");
          fetchInfluencerInformation();
        })
        .catch((error) => {
          console.error("Error making email primary:", error);
          alert("Failed to set primary email. Please try again.");
        });
    }
  };

  const handleMakePrimaryLocation = (location) => {
    if (window.confirm(`Are you sure you want to set "${location}" as the primary location?`)) {
      const requestBody = {
        typeOfRequest: "address",
        value: location,
        id: influencerAccount.id,
        action: "makePrimary",
      };
      editInfluencerDetails(requestBody, user, dispatch)
        .then(() => {
          alert("Primary location updated successfully.");
          fetchInfluencerInformation();
        })
        .catch((error) => {
          console.error("Error making location primary:", error);
          alert("Failed to set primary location. Please try again.");
        });
    }
  };

  // Handler passed to LocationInput to capture user’s selected address
  const handlePlaceSelected = (place, index) => {
    const address = place.formatted_address || place.name || "";
    const updatedLocs = [...editLocations];
    updatedLocs[index] = address;
    setEditLocations(updatedLocs);
  };

  return (
    <Modal
      open={viewContactDialog}
      onClose={handleModalClose}
      aria-labelledby="profile-modal-title"
      aria-describedby="profile-modal-description"
    >
      {isLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 3,
            textAlign: "center",
          }}
        >
          <Typography variant="h6">Loading...</Typography>
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isEditMode
              ? { xs: "95%", sm: "85%", md: "75%", lg: "70%" }
              : { xs: "90%", sm: "80%", md: "60%", lg: "55%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 0,
            borderRadius: 3,
            maxHeight: isEditMode ? "95vh" : "90vh",
            overflowY: "auto",
          }}
        >
          {/* ---------------- Header ---------------- */}
          <Grid
            container
            sx={{
              height: "74px",
              background: "linear-gradient(to right, #C4E4FF 0%, #3699FF 100%)",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 1%",
            }}
          >
            <Typography variant="h6" sx={{ color: "#fff" }} />
            <Box>
              {isEditMode ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChanges}
                    sx={{ marginRight: "10px" }}
                  >
                    Save
                  </Button>
                  <Button variant="outlined" color="secondary" onClick={handleCancelEdit}>
                    Cancel
                  </Button>
                </>
              ) : (
                <IconButton onClick={handleEditToggle} sx={{ color: "#fff" }}>
                  <Edit />
                </IconButton>
              )}
              {!isEditMode && (
                <IconButton onClick={handleModalClose} sx={{ marginLeft: "10px", color: "#fff" }}>
                  <Close />
                </IconButton>
              )}
            </Box>
          </Grid>

          {/* ---------------- Main Content ---------------- */}
          <Grid
            container
            sx={{
              margin: "0 4% 5% 4%",
              width: "96%",
              display: "flex",
              gap: "5%",
            }}
          >
            {/* Avatar and Platforms */}
            <Grid item>
              <Avatar
                src={influencerAccount.photoUrl || ""}
                {...stringAvatar(influencerAccount.name)}
                onError={(e) => {
                  e.target.src = configs.PROFILE_IMG;
                }}
                sx={{
                  width: 178,
                  height: 160,
                  borderRadius: 5,
                  position: "relative",
                  top: "-40px",
                }}
              />
              <Grid>
                {influencerInformation.platforms &&
                  influencerInformation.platforms.map(
                    (platformItem, idx) =>
                      platformItem.platform !== "google" && (
                        <Grid
                          item
                          key={idx}
                          style={{
                            marginTop: "8px",
                            color: "#000000",
                            fontSize: "14px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={textColor[platformItem.platform]}
                              style={{ paddingTop: "10px" }}
                            >
                              {snapshotHeaderIcons2[platformItem.platform]}
                            </Typography>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: "-7px",
                                }}
                              >
                                {platformItem.username === "" ? (
                                  <span style={{ fontSize: "20px" }}>-</span>
                                ) : (
                                  <span>@{platformItem.username}</span>
                                )}
                                {platformItem.profileurl !== "" && (
                                  <IconButton
                                    onClick={() =>
                                      window.open(platformItem.profileurl, "_blank")
                                    }
                                    size="small"
                                  >
                                    <OpenInNew style={{ fontSize: "14px" }} />
                                  </IconButton>
                                )}
                              </div>
                              <span>{platformItem.followers} followers</span>
                            </div>
                          </div>
                        </Grid>
                      )
                  )}
              </Grid>
            </Grid>

            {/* Profile Details */}
            <Grid item sx={{ width: "60%" }}>
              {/* Name and Primary Location */}
              <Grid item style={{ marginTop: "20px" }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: "#111111",
                    lineHeight: "40px",
                  }}
                >
                  {influencerInformation.names[0] || "-"}
                </Typography>

                <Box
                  style={{
                    fontSize: "14px",
                    color: "#94A2B3",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LocationOn style={{ fontSize: "19px" }} />
                  {editPrimaryLocation || "Location not found"}
                  {editPrimaryLocation && (
                    <span
                      style={{
                        fontStyle: "italic",
                        fontSize: "12px",
                        marginLeft: "5px",
                      }}
                    >
                      (primary)
                    </span>
                  )}
                </Box>
              </Grid>

              {/* Campaign Stats */}
              <Grid
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  gap: "2%",
                  flexWrap: "wrap",
                  color: "#1BC5BD",
                  fontSize: "12px",
                  width: "38vw",
                }}
              >
                <div style={{ width: "20%" }}>
                  Member since
                  <div style={{ color: "#111111", fontSize: "14px" }}>
                    {influencerInformation.createdAt
                      ? formatDate(influencerInformation.createdAt)
                      : "-"}
                  </div>
                </div>
                <div style={{ width: "20%" }}>
                  Active campaigns
                  <div style={{ color: "#111111", fontSize: "14px" }}>
                    {influencerInformation.campaignData?.activeCampaigns || 0}
                  </div>
                </div>
                <div style={{ width: "20%" }}>
                  Past campaigns
                  <div style={{ color: "#111111", fontSize: "14px" }}>
                    {influencerInformation.campaignData?.pastCampaigns || 0}
                  </div>
                </div>
                <div style={{ width: "20%" }}>
                  Contracts signed
                  <div style={{ color: "#111111", fontSize: "14px" }}>
                    {influencerInformation.campaignData?.contractsSigned || 0}
                  </div>
                </div>
              </Grid>

              {/* Contact Information and Demographics */}
              <Grid
                sx={{
                  display: "flex",
                  fontSize: "12px",
                  color: "#262222",
                  gap: "5%",
                }}
              >
                {/* Contact Information */}
                <div style={{ width: "60%" }}>
                  {/* Telephone */}
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={phoneIcon}
                      alt="Icon"
                      style={{ paddingBottom: "5px" }}
                    />
                    <span
                      style={{
                        color: "#111111",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      Tel :
                    </span>
                  </div>
                  {isEditMode ? (
                    editContacts.length > 0 ? (
                      // We have existing phone data -> only show them, NO "Add" button
                      editContacts.map((phone, index) => {
                        const { dialCode } = getPhoneDialAndNumber(phone);
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <MuiPhoneInput
                              country={dialCode ? dialCode : "us"}
                              value={getMuiPhoneValue(phone)}
                              onChange={(value, country) =>
                                handlePhoneInputChange(value, country, index)
                              }
                              InputProps={{ endAdornment: null }}
                              inputStyle={{
                                borderRadius: "6px",
                                border: "1px solid #d9d9dd",
                                boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                                height: "45px",
                                width: "220px",
                              }}
                            />
                          </div>
                        );
                      })
                    ) : (
                      // No phone data -> show exactly one empty field
                      <div style={{ marginTop: "5px" }}>
                        <MuiPhoneInput
                          country={"us"}
                          inputStyle={{
                            borderRadius: "6px",
                            border: "1px solid #d9d9dd",
                            boxShadow: "0px 2px 6px rgba(19, 18, 66, 0.07)",
                            height: "45px",
                            width: "220px",
                          }}
                          onChange={(value, country) => {
                            let dialCode = country.dialCode;
                            let cleanValue = value.replace(/[^0-9]/g, "");
                            if (cleanValue.startsWith(dialCode)) {
                              cleanValue = cleanValue.substring(dialCode.length);
                            }
                            setEditContacts([`${dialCode}-${cleanValue}`]);
                          }}
                        />
                      </div>
                    )
                  ) : originalContacts.length > 0 ? (
                    // Not in edit mode & we have phone data
                    originalContacts.map((phone, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <span>{phone}</span>
                        {phone === influencerInformation.primaryContactNumber?.value ? (
                          <span
                            style={{
                              fontSize: "12px",
                              fontStyle: "italic",
                              marginLeft: "5px",
                            }}
                          >
                            (primary)
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "12px",
                              fontStyle: "italic",
                              marginLeft: "5px",
                              cursor: "pointer",
                              color: "blue",
                            }}
                            onClick={() => handleMakePrimaryContactChange(phone)}
                          >
                            (Make it primary)
                          </span>
                        )}
                      </div>
                    ))
                  ) : (
                    // Not in edit mode & no phone data
                    <span style={{ fontSize: "20px", paddingLeft: "20px" }}>-</span>
                  )}

                  {/* Email */}
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img src={mailIcon} alt="Icon" style={{ paddingBottom: "5px" }} />
                    <span
                      style={{
                        color: "#111111",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      Email :
                    </span>
                  </div>
                  {isEditMode ? (
                    editEmails.length > 0 ? (
                      // We have existing emails -> show them, NO add
                      editEmails.map((email, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <TextField
                            size="small"
                            value={email || ""}
                            onChange={(e) => {
                              const updatedEmails = [...editEmails];
                              updatedEmails[index] = e.target.value;
                              setEditEmails(updatedEmails);
                            }}
                            sx={{ width: "70%", marginRight: "10px" }}
                            placeholder="e.g., example@domain.com"
                          />
                        </div>
                      ))
                    ) : (
                      // No email data -> show exactly one textfield
                      <TextField
                        size="small"
                        placeholder="e.g., example@domain.com"
                        onChange={(e) => setEditEmails([e.target.value])}
                        sx={{ width: "70%", marginTop: "5px" }}
                      />
                    )
                  ) : originalEmails.length > 0 ? (
                    // Not in edit mode & we have email data
                    originalEmails.map((email, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <span>{email}</span>
                        {email === influencerInformation.primaryEmail?.value ? (
                          <span
                            style={{
                              fontSize: "12px",
                              fontStyle: "italic",
                              marginLeft: "5px",
                            }}
                          >
                            (primary)
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "12px",
                              fontStyle: "italic",
                              marginLeft: "5px",
                              cursor: "pointer",
                              color: "blue",
                            }}
                            onClick={() => handleMakePrimaryEmail(email)}
                          >
                            (Make it primary)
                          </span>
                        )}
                      </div>
                    ))
                  ) : (
                    // Not in edit mode & no email data
                    <span style={{ fontSize: "20px", paddingLeft: "20px" }}>-</span>
                  )}

                  {/* Other Known Locations */}
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={locationIcon}
                      alt="Icon"
                      style={{ paddingBottom: "5px" }}
                    />
                    <span
                      style={{
                        color: "#111111",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      Other Known Locations :
                    </span>
                  </div>
                  {isEditMode ? (
                    editLocations.length > 0 ? (
                      // We have existing known locations -> just show them (no add)
                      editLocations.map((loc, idx) => (
                        <div
                          key={idx}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          {/* Use LocationInput instead of TextField */}
                          <LocationInput
                            placeSelectedHandler={(place) => handlePlaceSelected(place, idx)}
                            value={loc || ""}
                            onChange={(e) => {
                              // let user type partial addresses
                              const updatedLocs = [...editLocations];
                              updatedLocs[idx] = e.target.value;
                              setEditLocations(updatedLocs);
                            }}
                            size="small"
                            sx={{ width: "70%", marginRight: "10px" }}
                            placeholder="Start typing an address..."
                          />
                        </div>
                      ))
                    ) : (
                      // No known locations -> show exactly one LocationInput
                      <LocationInput
                        placeSelectedHandler={(place) => handlePlaceSelected(place, 0)}
                        value=""
                        onChange={(e) => {
                          const updatedLocs = [e.target.value];
                          setEditLocations(updatedLocs);
                        }}
                        size="small"
                        sx={{ width: "70%", marginTop: "5px" }}
                        placeholder="Start typing an address..."
                      />
                    )
                  ) : originalLocations.length > 0 ? (
                    // Not in edit mode & we have known locations
                    originalLocations.map((location, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <span>{location}</span>
                        <span
                          style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            marginLeft: "5px",
                            cursor: "pointer",
                            color: "blue",
                          }}
                          onClick={() => handleMakePrimaryLocation(location)}
                        >
                          (Make it primary)
                        </span>
                      </div>
                    ))
                  ) : (
                    // Not in edit mode & no known locations
                    <span style={{ fontSize: "20px", paddingLeft: "20px" }}>-</span>
                  )}
                </div>

                {/* Demographics */}
                <div>
                  {/* Gender */}
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={genderIcon}
                      alt="Icon"
                      style={{ paddingBottom: "5px" }}
                    />
                    <span
                      style={{
                        color: "#111111",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      Gender :
                    </span>
                    {originalGenders.length > 1 && new Set(originalGenders).size > 1 && (
                      <img
                        src={warningIcon}
                        alt="Icon"
                        style={{
                          paddingBottom: "5px",
                          paddingLeft: "5px",
                        }}
                      />
                    )}
                  </div>
                  {isEditMode ? (
                    editGenders.length > 0 ? (
                      // We have existing genders -> show them, no add
                      editGenders.map((genderItem, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <SelectField
                            label="Gender"
                            options={genderData}
                            value={genderItem || ""}
                            onChange={(e) => {
                              const updatedGenders = [...editGenders];
                              updatedGenders[index] = e.target.value;
                              setEditGenders(updatedGenders);
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      // No gender data -> one select
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <SelectField
                          label="Gender"
                          options={genderData}
                          value=""
                          onChange={(e) => setEditGenders([e.target.value])}
                        />
                      </div>
                    )
                  ) : originalGenders.length > 0 ? (
                    // Not in edit mode & we have gender data
                    originalGenders.map((genderItem, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <span>{genderItem}</span>
                        {originalGenders.length > 1 && index === 0 && (
                          <img
                            src={warningIcon}
                            alt="Warning"
                            style={{
                              paddingBottom: "5px",
                              paddingLeft: "5px",
                            }}
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    // Not in edit mode & no gender
                    <span style={{ fontSize: "20px", paddingLeft: "20px" }}>-</span>
                  )}

                  {/* Age Ranges */}
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={ageIcon}
                      alt="Icon"
                      style={{ paddingBottom: "5px" }}
                    />
                    <span
                      style={{
                        color: "#111111",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      Age :
                    </span>
                  </div>
                  {isEditMode ? (
                    editAgeRanges.length > 0 ? (
                      // We have existing ages -> show them, no add
                      editAgeRanges.map((ageRange, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <SelectField
                            label="Age Range"
                            options={ageData}
                            value={ageRange || ""}
                            onChange={(e) => {
                              const updatedAges = [...editAgeRanges];
                              updatedAges[index] = e.target.value;
                              setEditAgeRanges(updatedAges);
                            }}
                          />
                        </div>
                      ))
                    ) : (
                      // No age data -> show one select
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <SelectField
                          label="Age Range"
                          options={ageData}
                          value=""
                          onChange={(e) => setEditAgeRanges([e.target.value])}
                        />
                      </div>
                    )
                  ) : originalAges.length > 0 ? (
                    // Not in edit mode & we have age data
                    originalAges.map((ageItem, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <span>{ageItem}</span>
                      </div>
                    ))
                  ) : (
                    // Not in edit mode & no age data
                    <span style={{ fontSize: "20px", paddingLeft: "20px" }}>-</span>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Modal>
  );
};

ContactCard.propTypes = {
  platform: PropTypes.object,
  influencerAccount: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    photoUrl: PropTypes.string,
    location: PropTypes.string,
  }).isRequired,
  viewContactDialog: PropTypes.bool.isRequired,
  closeContactDialog: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default ContactCard;
