import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

const ExpandCustomTable = ({ headers, toExpandIds, infoForExpandIds, AccountId }) => {
    const [openRows, setOpenRows] = useState([]);

    useEffect(() => {
        const rowData = toExpandIds
            .map((id) => ({
                id,
                data: infoForExpandIds.find((info) => info[id]), // Find the matching data for the ID
            }))
            .filter((item) => item.data && item.id === AccountId);

        setOpenRows(rowData)

    }, [AccountId])


    // const toggleRow = (rowId) => {
    //     setOpenRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
    // };

    return (
        <TableContainer component={Paper} >
            <Table>
                {/* Table Head */}
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell key={header.id} align={header.headeralignment || "left"}>
                                {header.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>

                {/* Table Body */}
                {/* Table Body */}
                <TableBody>
                    {console.log(openRows)}
                    {openRows?.map(({ id, data }) =>
                        data[id]?.map((item, index) => (
                            <TableRow key={`${id}-${index}`}>
                                {headers.map((header) => (
                                    <TableCell key={header.id} align={header.headeralignment || "left"}>
                                        {item[header.headerAssignment] || "-"}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    )}
                </TableBody>

            </Table>
        </TableContainer>
    );
};

export default ExpandCustomTable;
