import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled, TableSortLabel, Checkbox, Select, MenuItem, PaginationItem } from "@mui/material";
import Pagination from '@mui/material/Pagination';
import styles from "./CustomTable.module.css";
import ExpandCustomTable from "../ExpandCustomTable/ExpandCustomTable";

const StyledTableCell = styled(TableCell)(({ theme, colors }) => ({
  [`&.${tableCellClasses.head}`]: {
    border: "none",
    color: colors?.header?.textColor ? colors?.header?.textColor : "black",
  },
  [`&.${tableCellClasses.body}`]: {
    border: "none",
    color: colors?.body?.textColor ? colors?.header?.textColor : "black",
    backgroundColor: colors?.body?.backgroundColor
      ? colors?.body?.backgroundColor
      : "white",
  },
}));
const PaginationActions = ({ count, page, onPageChange, rowsPerPage, onRowsPerPageChange }) => {
  const totalPages = Math.ceil(count / rowsPerPage);

  const handlePageChange = (event, newPage) => {
    onPageChange(event, newPage - 1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
        mt: 2,
        overflowY: 'hidden',
      }}
    >
      <Box sx={{ flex: 1 }} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Pagination
          count={totalPages}
          page={page + 1}
          onChange={handlePageChange}
          renderItem={(item) => {
            if (item.type === 'previous' || item.type === 'next') {
              return (
                <PaginationItem
                  {...item}
                  sx={{
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    borderRadius: '4px',
                    margin: '0 4px',
                    padding: '0 8px',
                    '&:hover': {
                      bgcolor: '#f0f0f0',
                    },
                    '&.Mui-selected': {
                      bgcolor: '#34CC99',
                      color: 'white',
                    },
                  }}
                >
                  {item.type === 'previous' ? '< Prev' : 'Next >'}
                </PaginationItem>
              );
            }
            return (
              <PaginationItem
                {...item}
                sx={{
                  '&.MuiPaginationItem-page': {
                    border: '1px solid',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    borderRadius: '4px',
                    margin: '0 4px',
                    padding: '0 8px', // Adjust padding to match Select box
                  },
                  '&.Mui-selected': {
                    bgcolor: '#34CC99',
                    color: 'white',
                  },
                  '&.MuiPaginationItem-ellipsis': {
                    border: 'none',
                    margin: '0 4px',
                  },
                }}
              />
            );
          }}
        />
      </Box>
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}> {/* Spacer to push Select to right */}
        <Select
          value={rowsPerPage}
          onChange={onRowsPerPageChange}
          sx={{
            border: '1px solid',
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            margin: '0 4px',
            padding: '0 8px',
            '&:hover': {
              bgcolor: '#f0f0f0',
            },
            height: '36px', // Adjust height to match PaginationItem
            minWidth: '56px', // Adjust width to match PaginationItem
          }}
        >
          {[10, 20, 30, 50].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

// function to display headers of the tables 
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, tableHeaders } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property); // a callback function that is invoked when a column header is clicked for sorting.
  };

  return (
    <TableHead>
      <TableRow>
        {tableHeaders.map(
          (headCell) =>
            headCell.show && (
              <StyledTableCell
                key={headCell.id}
                align={headCell.headeralignment}
                // aligh sets based on the headeralignment property of each headCell.
                sortDirection={orderBy === headCell.sortParameter ? order : false}
              //sortDirection indicates the sorting direction (asc or desc) for the column 
              //( col selected for sort == headCell.sortParameter ) ? asc/dec(value of order) : no sort(false);
              >
                {headCell.sortable ? (
                  <TableSortLabel
                    //TableSortLabel is a Material-UI component used for sortable table headers.
                    active={orderBy === headCell.sortParameter}
                    //The active prop of TableSortLabel is set based on whether the current header cell is the one being sorted by.
                    direction={orderBy === headCell.sortParameter ? order : "asc"}
                    //The direction prop determines the sorting direction arrow displayed next to the header label.
                    onClick={createSortHandler(headCell.sortParameter)}
                  //will trigger sorting based on the sortParameter property of the header cell when clicked.
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span">
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </StyledTableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  tableHeaders: PropTypes.array.isRequired,
};

const CustomizedRow = ({
  row,
  headerColumns,
  expand,
  selectedRow,
  rowSelectionUpdated,
  colors,
}) => {
  React.useEffect(() => { }, [
    expand,
    selectedRow,
    row,
    headerColumns,
    rowSelectionUpdated,
    colors,
  ]);


  return (
    <>
      <TableRow hover key={row.id} sx={{ paddingInline: "3px" }}>
        {headerColumns.map((header) => {
          return header.show && header.checkbox ? (
            // to_do
            <StyledTableCell
              align="left"
              style={{ width: 5, padding: "0" }}
              colors={colors}
            >
              <Checkbox
                checked={!!selectedRow[row.id]}
                //It checks if selectedRow[row.id] is truthy. If it is, the checkbox will be checked; otherwise, it will be unchecked. 
                onChange={() => {
                  rowSelectionUpdated(row);
                }}
              />
            </StyledTableCell>
          ) : (
            <StyledTableCell
              key={header.id}
              align={header.alignment}
              scope="row"
            >
              {row[header.headerAssignment]}
            </StyledTableCell>
          );
        })}
      </TableRow>
      {/* to_do */}
      {/* {expand !== undefined && expand[row.selectedId] && (
        <TableRow
          key={row.id}
          sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        >
          {row.expand.map((cell) => (
            <StyledTableCell
              key={row.id}
              align="left"
              colSpan={cell.colSpan}
              scope="row"
            >
              {cell.content}
            </StyledTableCell>
          ))}
        </TableRow>
      )} */}
    </>
  );
};

const CustomTable = ({
  tableHeaders, // headers of the table 
  subtableHeaders,
  bodyColumns, // tableData(details of campaigns)
  expand,
  sendInformation, // function that changes the info to display such as , page no, no of rows , order of col 
  totalRows, // total no of campaigns
  pageNo,
  rowsPerPageNo,
  sortOrder,
  sortOrderBy,
  rowSelectionChanged,
  Pagination,
  influencerData,
  finalSelectedProfiles,
  toExpandIds,
  infoForExpandIds
}) => {
  const [order, setOrder] = React.useState(sortOrder); // Represents the current sorting order (e.g., asc desc).
  const [orderBy, setOrderBy] = React.useState(sortOrderBy); //Represents the currently selected column for sorting
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(pageNo); // refers to page no of pagination
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageNo); // refers to no of rows dispaly at a time on page
  const [selectedRows, setSelectedRow] = React.useState({});
  const [selectedExpandRows, setSelectedExpandRows] = React.useState({});
  const [selectionUpdated, setSelectionUpdated] = React.useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    //Check if the current order is ascending for the clicked property , returns ture if asc or else false 
    setOrder(isAsc ? "desc" : "asc");
    //// Set the order to the opposite of the current order
    setOrderBy(property);
    // // Set the property to be ordered by
    handleInformationChange(
      page,
      rowsPerPage,
      isAsc ? "desc" : "asc",
      property
    );
  };

  const handleInformationChange = (page, rowsPerPage, order, orderBy) => {
    sendInformation({ page, rowsPerPage, order, orderBy });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleInformationChange(newPage, rowsPerPage, order, orderBy);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleInformationChange(
      page,
      parseInt(event.target.value, 10),
      order,
      orderBy
    );
    setPage(0);
  };

  const rowSelectionUpdated = (row) => {
    const rowId = row.id;
    let rowSelectionObj;

    if (influencerData && finalSelectedProfiles) {
      const commonInfluencerIds = finalSelectedProfiles
        .filter((profile) =>
          influencerData.some((data) => data.influencer_id === profile.influencer_id)
        )
        .map((profile) => profile.influencer_id);
      const commonIndices = commonInfluencerIds.map((id) =>
        influencerData.findIndex((data) => data.influencer_id === id)
      );
      // Transform commonIndices into the desired object
      const indexObject = commonIndices.reduce((acc, index) => {
        acc[index + 1] = true; // Increment the index by 1 and set value to true
        return acc;
      }, {});
      rowSelectionObj = { ...selectedRows, ...indexObject };
    }
    else {
      rowSelectionObj = selectedRows;
    }
    if (rowSelectionObj[rowId]) {
      delete rowSelectionObj[rowId];
    } else {
      rowSelectionObj[rowId] = true;
    }
    setSelectedRow(rowSelectionObj);
    setSelectionUpdated(!selectionUpdated);
    rowSelectionChanged(rowSelectionObj);
  };

  React.useEffect(() => {
    if (influencerData) {
      const commonInfluencerIds = finalSelectedProfiles
        .filter((profile) =>
          influencerData.some((data) => data.influencer_id === profile.influencer_id)
        )
        .map((profile) => profile.influencer_id);
      // Find the indices of these common IDs in influencerData
      const commonIndices = commonInfluencerIds.map((id) =>
        influencerData.findIndex((data) => data.influencer_id === id)
      );
      // Transform commonIndices into the desired object
      const indexObject = commonIndices.reduce((acc, index) => {
        acc[index + 1] = true; // Increment the index by 1 and set value to true
        return acc;
      }, {});
      setSelectedRow(indexObject)
    }
  }, [influencerData]);

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer sx={{ maxWidth: "100%" }}>
        <Table
          style={{ overflow: "hidden" }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <EnhancedTableHead
            // numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            // rowCount={bodyColumns?.length}
            tableHeaders={tableHeaders}
          />
          <TableBody className={styles.container}>
            {bodyColumns.map((rowData, index) => {
              return (
                <React.Fragment key={index}>
                  {/* Main row */}
                  <CustomizedRow
                    row={rowData}
                    headerColumns={tableHeaders}
                    expand={expand}
                    selectedRow={selectedRows}
                    rowSelectionUpdated={rowSelectionUpdated}
                  />
                  <span style={{ display: "block", marginBottom: "12px" }} />

                  {/* Expandable table row */}
                  {rowData.Expand && (
                    <TableRow>
                      <TableCell colSpan={tableHeaders.length} style={{ paddingTop: 0 }}>
                        <ExpandCustomTable
                          headers={subtableHeaders}
                          toExpandIds={toExpandIds}
                          infoForExpandIds={infoForExpandIds}
                          AccountId={rowData.AccountId}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {Pagination !== false && (
        <TablePagination
          component="div"
          count={totalRows}
          page={page}
          onPageChange={handleChangePage}
          ActionsComponent={(subProps) => (
            <PaginationActions
              {...subProps}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          labelDisplayedRows={() => ''}
          sx={{
            '& .MuiTablePagination-spacer': {
              flex: 'none',
            },
            '& .MuiTablePagination-actions': {
              display: 'none',
            },
          }}
        />
      )}
    </Box>

  );
};

CustomTable.propTypes = {
  tableHeaders: PropTypes.array.isRequired,
  bodyColumns: PropTypes.array.isRequired,
  expand: PropTypes.bool,
  sendInformation: PropTypes.func,
  selectedRow: PropTypes.object,
  totalRows: PropTypes.number.isRequired,
  pageNo: PropTypes.number.isRequired,
  rowsPerPageNo: PropTypes.number.isRequired,
  sortOrder: PropTypes.string.isRequired,
  sortOrderBy: PropTypes.string.isRequired,
};

export default CustomTable;
