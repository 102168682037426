import React from "react";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import { TextField } from "@mui/material";

const MuiPhoneInput = ({ InputProps = {}, ...other }) => {
  const { endAdornment, ...inputProps } = InputProps;

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <MuiPhoneNumber
        {...other}
        defaultCountry={"us"}
        variant="outlined"
        fullWidth
        inputProps={{
          ...inputProps,
        }}
        TextFieldProps={{
          InputProps: {
            ...inputProps,
            endAdornment: null, 
          },
        }}
      />
      {endAdornment && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            right: "15px",
            transform: "translateY(-50%)",
            display: "flex",
            alignItems: "center",
          }}
        >
          {endAdornment}
        </div>
      )}
    </div>
  );
};

MuiPhoneInput.propTypes = {
  InputProps: PropTypes.shape({
    endAdornment: PropTypes.element,
  }),
};

export default MuiPhoneInput;
