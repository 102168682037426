import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Box,
  Stack,
  Typography,
  TableContainer
} from '@mui/material';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CloseIcon from '@mui/icons-material/Close';
import { WorldMap } from 'react-svg-worldmap';

const TopCountries = ({ data, backgroundColor }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    if (data?.length > 0) {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const mapData = data?.map(item => ({
    country: item.value,
    value: item.clicks,
  }));

  return (
    <Card
      style={{
        padding: 20,
        height: '400px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
      <Typography variant="h4" sx={{color: '#33CC99', marginBottom: 1 }}>Top Countries</Typography>
        <IconButton
          aria-label="expand"
          onClick={handleOpenModal}
          disabled={data?.length === 0}
          color="primary"
        >
          <OpenWithIcon />
        </IconButton>
      </Stack>

      {/* Countries Table */}
      {data?.length > 0 ? (
        <Table stickyHeader size="small" aria-label="Top Countries Table">
          <TableHead>
            <TableRow>
              <TableCell><b>Country</b></TableCell>
              <TableCell align="right"><b>Clicks</b></TableCell>
              <TableCell align="right"><b>Percentage</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index} hover>
                <TableCell>{row.value}</TableCell>
                <TableCell align="right">{row.clicks}</TableCell>
                <TableCell align="right">{row.percentage}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="body2" align="center" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          No Data Found
        </Typography>
      )}

      {/* Modal for Detailed View */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="top-countries-modal-title"
        aria-describedby="top-countries-modal-description"
        closeAfterTransition
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "90%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          {/* Modal Header */}
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography id="top-countries-modal-title" variant="h4" component="h2">
              Top Countries Details
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Stack>

          {/* Detailed Content */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card style={{ padding: 20, height: '100%' }}>
                <TableContainer
                  style={{
                    maxHeight: 500,
                    overflow: 'auto',
                  }}
                >
                  <div
                    style={{
                      maxHeight: 'inherit',
                      overflow: 'scroll',
                      scrollbarWidth: 'none',
                      msOverflowStyle: 'none',
                    }}
                    className="scroll-container"
                  >
                    <Table stickyHeader size="small" aria-label="Detailed Top Countries Table">
                      <TableHead>
                        <TableRow>
                          <TableCell><b>Country</b></TableCell>
                          <TableCell align="right"><b>Clicks</b></TableCell>
                          <TableCell align="right"><b>Percentage</b></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.map((row, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{row.value}</TableCell>
                            <TableCell align="right">{row.clicks}</TableCell>
                            <TableCell align="right">{row.percentage}%</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                style={{
                  padding: 20,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  backgroundColor: backgroundColor,
                }}
              >
                <div style={{ transform: 'scale(1.2)', transformOrigin: 'center' }}>
                  <WorldMap
                    color="green"
                    value-suffix=" Clicks"
                    size="responsive"
                    data={mapData}
                    borderColor={'black'}
                    strokeOpacity={0.3}
                  />
                </div>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Card>
  );
};

TopCountries.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      clicks: PropTypes.number.isRequired,
      percentage: PropTypes.number.isRequired,
    })
  ).isRequired,
  backgroundColor: PropTypes.string,
};

export default TopCountries;
