import { Alert, Box, Button, Snackbar, Stack, Tab, Tabs, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchItem from "./SearchItem";
import { useSelector } from "react-redux";
import { UNAUTH_API_HOST, API_HOST } from "../../config/main.json";
import axios from "axios";
import CustomTable from "../../common/CustomTable/CustomTable";
import { getPeopleAssignmentTableHeaders, getSubPeopleAssignmentTableHeaders } from "../../utils/tableHeaders";
import Loader from "./Loader";
import {
    ChevronRightOutlined,
    KeyboardArrowDown,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";


const optionAxios = {
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
};

function PeopleAssignment() {
    const [searchBy] = useState("Name");
    const [tablePageNo, setTablePageNo] = useState(0);
    const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
    const [sortCol, setSortCol] = useState("c.current_status");
    const [sortOrder, setSortOrder] = useState("asc");
    const [filter, setFilter] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [dataFound, setDataFound] = useState(false);
    const [accountData, setAccountData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [resetPageNo, setResetPageNo] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [expand, setExpand] = React.useState([]);
    const [selectedRow, setSelectedRow] = React.useState({});
    const [campaignsData, setCampaignsData] = React.useState([]);// arry have all selected account id and campaigns in object form 
    const [selectedAccount, setSelectedAccount] = useState([]);// arry have all selected account id (extended)
    const [refresh, setRefresh] = useState(true);
    const [totalRecords, setTotalRecords] = useState()

    const user = useSelector((state) => state.user);

    const styles = {
        noData: {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            fontSize: "large",
            fontStyle: "italic",
        },
    };

    useEffect(() => {
        const array = Array.apply(null, Array(tableRowsPerPage))?.map(function () { });
        fetchData();
    }, [tablePageNo,
        tableRowsPerPage,
        sortCol,
        sortOrder,
        filter]);

    const fetchData = () => {
        setIsLoaded(() => false);
        getTableData(
            tablePageNo + 1,
            tableRowsPerPage,
            sortCol,
            sortOrder,
            filter
        );
    };

    const getTableData = async (tablePageNo, tableRowsPerPage, sortCol, sortOrder, filter) => {
        setIsLoaded(false);

        const response = await axios({
            url: `${API_HOST}/access/listByCampaign`,
            method: "POST",
            data: {
                page: tablePageNo,
                rows: tableRowsPerPage,
                SortCol: sortCol,
                sortOrder: sortOrder,
                search: filter
            },
            headers: optionAxios.headers,
        });

        if (response.data.accounts) {
            const records = response.data.accounts;;
            setAccountData(records);
            setTotalRecords(response.data.total);
            setDataFound(true);
        } else {
            setDataFound(false);
        };

        setIsLoaded(true);
    };

    const handleSearch = (event) => {
        setFilter(event);
        setTablePageNo(0);
    };

    const expandClick = async (row, index) => {
        let updatedAccounts;
        let campaignsArray;

        const response = await axios({
            method: "GET",
            url: `${API_HOST}/access/listByCampaign/${row.accountId}`,
        });

        if (response.status === 200) {
            updatedAccounts = [...(selectedAccount || []), row.accountId];
            campaignsArray = [...(campaignsData || []), response.data];
            const combinedArray = updatedAccounts.map((accountId, index) => ({
                [accountId]: campaignsArray[index],
            }));
            const keyCount = combinedArray.reduce((countMap, obj) => {
                const key = Object.keys(obj)[0];
                countMap[key] = (countMap[key] || 0) + 1;
                return countMap;
            }, {});
            const uniqueCombinedArray = combinedArray.filter((obj) => {
                const key = Object.keys(obj)[0];
                return keyCount[key] === 1; // Retain only if the key appears exactly once
            });

            setCampaignsData(uniqueCombinedArray)
        }

        setSelectedAccount((prevAccounts) => {
            // Check if row.accountId is already in the array
            const updatedAccounts = (prevAccounts || []).includes(row.accountId)
                ? (prevAccounts || []).filter((id) => id !== row.accountId) // Remove it if present
                : [...(prevAccounts || []), row.accountId]; // Add it if not present

            return updatedAccounts;
        });

        setRefresh(!refresh);
        setSelectedRow(row);
        changeExpansionArray(index);
    };

    const changeExpansionArray = (index) => {
        const newExpandedRows = [...expand];
        newExpandedRows[index] = !newExpandedRows[index];
        setExpand(newExpandedRows);
    };


    const handleInformationChange = (newPage, rowsPerPage, order, orderBy) => {
        setTablePageNo(newPage.page);
        setResetPageNo(false);
        setIsChanged(!isChanged);
        setTableRowsPerPage(newPage.rowsPerPage);
        setSortCol(newPage.orderBy);
        setSortOrder(newPage.order);
    };

    useEffect(() => {

        const data = [];
        if (accountData) {
            accountData.forEach((item, index) => {
                const {
                    accountId,
                    accountManager,
                    accountName,
                    noOfMembers
                } = item;

                const isExpanded = selectedAccount.includes(accountId);

                const object = {
                    Expand: isExpanded,
                    AccountId: accountId,
                    AccountName: (
                        <Grid container spacing={1} alignItems="center">
                            <Grid item sx={{ marginBlock: "auto", marginRight: "0.1vw" }}>
                                <IconButton onClick={() => expandClick(item, index)}>
                                    {isExpanded ? <KeyboardArrowDown /> : <ChevronRightOutlined />}
                                </IconButton>
                            </Grid>
                            <Grid  >
                                <Grid item xs={12} sx={{}}>
                                    {accountName}
                                </Grid>
                            </Grid>
                        </Grid>
                    ),
                    account_manager: (
                        <Grid container >
                            <Grid item xs={12} sx={{ width: "50px" }}>
                                {accountManager}
                            </Grid>
                        </Grid>
                    ),
                    noOfMembers: (
                        <Grid container >
                            <Grid item xs={12} sx={{}}>
                                {noOfMembers === "" ? "-" : noOfMembers}
                            </Grid>
                        </Grid>
                    ),
                };
                data.push(object);
            });
            setTableData(data);
        }
    }, [accountData, refresh])

    const peopleAssignmentTableHeaders = getPeopleAssignmentTableHeaders;
    const subPeopleAssignmentTableHeaders = getSubPeopleAssignmentTableHeaders;

    const renderContentHandler = () => {
        let content = null;
        if (isLoaded) {
            if (dataFound) {
                content = (
                    <>
                        <div id="table">
                            {/* This is a custom made component, take care while changing field names */}
                            <CustomTable
                                tableHeaders={peopleAssignmentTableHeaders}
                                subtableHeaders={subPeopleAssignmentTableHeaders}
                                bodyColumns={tableData}
                                expand={expand}
                                sendInformation={handleInformationChange}
                                totalRows={totalRecords}
                                pageNo={tablePageNo}
                                rowsPerPageNo={tableRowsPerPage}
                                sortOrder={sortOrder}
                                sortOrderBy={sortCol}
                                toExpandIds={selectedAccount}
                                infoForExpandIds={campaignsData}
                            />
                        </div>
                    </>
                );
            } else {
                content = (
                    <Grid container spacing={2}>
                        {/* No brand found */}
                        <Grid style={styles.noData} item xs={12}>
                            You don't have any Information at the moment.
                        </Grid>
                    </Grid>
                );
            }
        } else content = <Loader />;

        return content;
    };


    return (
        <>
            <h5 style={{ padding: "10px" }}>
                <b>People Assignment</b>
            </h5>
            <Box display={"flex"} alignItems={"center"} justifyContent={"end"}>
                <Stack direction="row" justifyContent="space-between">
                    <SearchItem sendSearchedValue={handleSearch} searchBy={searchBy} />
                </Stack>
            </Box>
            <br />
            <Box>{renderContentHandler()}</Box>
        </>
    );
}

export default PeopleAssignment;
