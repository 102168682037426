import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

const TopCities = ({ data }) => {
  return (
    <Card
      style={{
        padding: 20,
        height: '400px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h4" sx={{color: '#33CC99', marginBottom: 1 }}>Top Cities</Typography>

      {/* Cities Table */}
      {data?.length > 0 ? (
        <Table stickyHeader size="small" aria-label="Top Cities Table">
          <TableHead>
            <TableRow>
              <TableCell><b>City</b></TableCell>
              <TableCell align="right"><b>Clicks</b></TableCell>
              <TableCell align="right"><b>Percentage</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, index) => (
              <TableRow key={index} hover>
                <TableCell>{row.city}</TableCell>
                <TableCell align="right">{row.clicks}</TableCell>
                <TableCell align="right">{row.percentage}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="body2" align="center" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          No Data Found
        </Typography>
      )}
    </Card>
  );
};

TopCities.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string.isRequired, 
      clicks: PropTypes.number.isRequired,
      percentage: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default TopCities;
