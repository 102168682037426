// src/components/linkAnalysis/LinkAnalysis.js

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import {
  getCampaignData, filterLinks,
  getTotalClicksSummary,
  getDevicesData,
  getReferrersData,
  getCountryLocationData,
  getCityLocationData,
  getLinkInfo,
  getOSSummary,
  getBrowserSummary,
  sendXlsxReport
} from "./linkAnalysisService";

/* external files */
import { API_HOST } from "../../config/main.json";

/* component external files */
import enums from "./enums";
import LinkFilter from "./LinkFilter";
import LinkUrl from "./LinkUrl";
import LinkAnalysisChart from "./LinkAnalysisChart";
import TopCountries from "./TopCountries";
import TopCities from "./TopCities";
import helper from "./helper";
import countryCodes from "./countryCodes";
import slides from "../../common/googleSlides.png";

/* mui */
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Tab,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Tabs,
  Typography,
  Card
} from "@mui/material";
import Datatables from "mui-datatables";
import utils from "../../utils/utils";
import MyPaper from "../general/MyPaper";
import { Analytics, Download, ManageAccounts, ManageSearch } from "@mui/icons-material";
import GeneratePptReport from "../generatePpt/GeneratePptReport";
import CampaignActions from "../../pages/account/CampaignActions";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../utils/campaignTabsArray";

let tabArray;

class LinkAnalysis extends Component {
  constructor(props) {
    super(props);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const campId = params.get("id");
    tabArray = campaignTabsArray(campId);
    this.state = {
      campId: campId,
      linkToAddPost: "/campaigns/addPost?id=" + campId,
      lists: [],
      influencers: [],
      urls: [],
      selectedUrlId: [],
      totalClicksData_daily: [],
      totalClicksData_cumulative: [],
      defaultStyles: {},
      linkInfo_tableData: [],
      linkInfo_tableOptions: {},
      linkInfo_tableColumns: [],
      location_tableOptions: {},
      countryLocation_tableColumns: [],
      cityLocation_tableColumns: [],
      countryLocationDataForTable: [],
      cityLocationDataForTable: [],
      startDate: moment().subtract(29, "days").toDate(),
      endDate: moment().toDate(),
      campaignName: "",
      campaignStart: "",
      campaignEnd: "",
      showLinksFilter: false,
      selectedUrls: [],
      notification: false,
      type: "",
      message: "",
      pptReportId: 0,
      osData: [],
      browserData: [],
    };
  }

  componentDidMount() {
    this.loadDefaultStyles();
    this.getCampaignInfo();
  }

  loadDefaultStyles = () => {
    const defaultStyles = enums.defaultStyles;
    const defaultMenu = enums.defaultMenu;
    this.setState({ defaultStyles, defaultMenu });
  };

  getAllUrls = async (lids, urlIds, platform) => {
    let cid = -1;
    if (platform === "all") {
      cid = parseInt(this.state.campId);
    }
    const payload = {
      cid: cid,
      lids: lids,
      urlIds: urlIds,
    };
    try {
      const res = await filterLinks(payload);
      let urls = res ? res : [];
      if (urls.length > 0) {
        const selectedUrlIds = urls.map((url) => url.id);
        this.getUrlData(selectedUrlIds, this.state.startDate, this.state.endDate, urlIds);
      } else {
        this.setState({
          totalClicksData_daily: { labels: [], datasets: [] },
          totalClicksData_cumulative: { labels: [], datasets: [] },
          devicesData: { labels: [], datasets: [] },
          referrersData: { labels: [], datasets: [] },
          locationData: [],
          countryLocationDataForTable: [],
          cityLocationDataForTable: [],
          linkInfo_tableData: [],
          osData: { labels: [], datasets: [] },
          browserData: [],
        });
      }
      this.setState({ urls, selectedUrls: urls.map((url) => url.id) });
    } catch (error) {
      this.setState({
        notification: true,
        type: "error",
        message: error.message || "An error occurred while fetching URLs.",
      });
    }
  };

  getUrlData = async (shortUrlIds, startDate, endDate, urlIds) => {
    const sDate = moment(startDate).format("YYYY-MM-DD");
    const eDate = moment(endDate).format("YYYY-MM-DD");
    // Fetch existing summaries
    try {
      await Promise.all([
        this.getTotalClicksData(shortUrlIds, sDate, eDate),
        this.getDevicesData(shortUrlIds, sDate, eDate),
        this.getReferrersData(shortUrlIds, sDate, eDate),
        this.getCountryLocationData(shortUrlIds, sDate, eDate),
        this.getCityLocationData(shortUrlIds, sDate, eDate),
        this.getLinkInfo(shortUrlIds, sDate, eDate, urlIds),
        this.getOSSummary(shortUrlIds, sDate, eDate),
        this.getBrowserSummary(shortUrlIds, sDate, eDate),
      ]);
    } catch (error) {
      // Errors are already handled in individual methods
      console.error("Error fetching URL data:", error);
    }
  };

  getCampaignInfo = async () => {
    const campaignId = this.state.campId;
    try {
      const data = await getCampaignData(campaignId);
      this.setState({
        campaignName: data.name,
        campaignStart: data.startDate.split("T")[0],
        campaignEnd: data.endDate.split("T")[0],
        showLinksFilter: true,
      });
    } catch (error) {
      console.error(error)
    }
  };

  getTotalClicksData = async (shortUrlIds, startDate, endDate) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };
    try {
      const totalClicksData = await getTotalClicksSummary(payload);
      const totalClicksData_daily = helper.restructureDataForChart(
        totalClicksData["daily"],
        "bar",
        "daily",
        1,
        "#4FD1C5"
      );
      const totalClicksData_cumulative = helper.restructureDataForChart(
        totalClicksData["cumulative"],
        "bar",
        "cumulative",
        1,
        "#4FD1C5"
      );
      this.setState({ totalClicksData_daily, totalClicksData_cumulative });
    } catch (error) {
      console.error(error)
    }
  };

  getDevicesData = async (shortUrlIds, startDate, endDate) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };
    try {
      const res = await getDevicesData(payload);
      const devicesData = helper.restructureDataForChart(res, "doughnut", "Devices");
      this.setState({ devicesData });
    } catch (error) {
      console.error(error)
    }
  };

  getReferrersData = async (shortUrlIds, startDate, endDate) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };
    try {
      const res = await getReferrersData(payload);
      const referrersData = helper.restructureDataForChart(res, "bar", "Referrers", true);
      this.setState({ referrersData });
    } catch (error) {
      console.error(error)
    }
  };

  getCountryLocationData = async (shortUrlIds, startDate, endDate) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };
    try {
      const res = await getCountryLocationData(payload);
      const locationData = res?.map(item => ({
        value: item.value,
        clicks: item.clicks,
        percentage: item.percentage,
      }));
      const countryLocationDataForTable = res?.map(item => ({
        value: item.value,
        clicks: item.clicks,
        percentage: item.percentage,
      }));
      this.setState({
        countryLocationDataForTable,
        location_tableOptions: enums.location_tableOptions,
        countryLocation_tableColumns: enums.countryLocation_tableColumns,
      });
    } catch (error) {
      console.error(error)
    }
  };

  getCityLocationData = async (shortUrlIds, startDate, endDate) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };
    try {
      const res = await getCityLocationData(payload);

      // Ensure `res` is an array
      const cityLocationDataForTable = Array.isArray(res)
        ? res.map(item => ({
          city: item.city,
          clicks: item.clicks,
          percentage: item.percentage,
        }))
        : [];
      this.setState({
        cityLocationDataForTable,
        location_tableOptions: enums.location_tableOptions,
        cityLocation_tableColumns: enums.cityLocation_tableColumns,
      });
    } catch (error) {
      console.log(error);
    }
  };


  getLinkInfo = async (shortUrlIds, startDate, endDate, urlIds) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };
    let linkInfo_tableOptions, linkInfo_tableColumns;
    if (urlIds.length <= 1) {
      linkInfo_tableOptions = enums.linkInfo_tableOptions;
      linkInfo_tableColumns = enums.linkInfo_tableColumns;
    } else {
      linkInfo_tableOptions = enums.linkInfo_multiTableOptions;
      linkInfo_tableColumns = enums.linkInfo_multiCableColumns;
    }
    try {
      const res = await getLinkInfo(payload);
      let linkInfo_tableData = [];
      if (urlIds.length <= 1) {
        const data = res ? res : [];
        linkInfo_tableData = data.map((row) => ({
          influencerNameAndUsername: `${row.influencerName} ${row.userName ? "(@" + row.userName + ")" : ""}`,
          ...row,
        }));
      } else {
        const tableData = res ? res : [];
        const destinationUrlObject = {};
        tableData.forEach((row) => {
          if (!destinationUrlObject[row.baseDestinationId]) {
            destinationUrlObject[row.baseDestinationId] = [];
          }
          destinationUrlObject[row.baseDestinationId].push(row);
        });
        Object.keys(destinationUrlObject).forEach((id) => {
          const table = {};
          table.destinationId = id;
          table.destinationUrl = destinationUrlObject[id][0].baseDestinationUrl;
          table.group = {
            platform: destinationUrlObject[id][0].platform,
            list: destinationUrlObject[id][0].listName,
          };
          let totalClicks = 0;
          const data = [];
          destinationUrlObject[id].forEach((row) => {
            totalClicks += row.clicks;
            row.links = {
              short: row.shortUrl,
              destination: row.destinationUrl,
            };
            row.influencerNameAndUsername = (
              <a href={row.profileUrl} target="_blank" rel="noopener noreferrer">
                {`${row.influencerName} ${row.userName ? "(@" + row.userName + ")" : ""}`}
              </a>
            );
            data.push(row);
          });
          table.expansion = (
            <TableRow
              sx={{
                ".MuiPaper-root": {
                  boxShadow: "none",
                },
              }}
              style={{
                backgroundColor: "#f6f1f1",
                width: "90%",
                alignContent: "center",
              }}
            >
              <TableCell colSpan={12} sx={{ padding: "0px 0px 0px 24px" }}>
                <Datatables data={data} columns={enums.subTableColumns} options={enums.subTableOptions} />
              </TableCell>
            </TableRow>
          );
          table.totalClicks = totalClicks;
          table.data = data;
          linkInfo_tableData.push(table);
        });
      }
      this.setState({
        linkInfo_tableData,
        linkInfo_tableOptions,
        linkInfo_tableColumns,
        selectedUrlId: urlIds,
      });
    } catch (error) {
      console.error(error)
    }
  };

  getOSSummary = async (shortUrlIds, startDate, endDate) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };
    try {
      const res = await getOSSummary(payload);
      const osData = helper.restructureDataForChart(res, "doughnut", "Operating Systems");
      this.setState({ osData });
    } catch (error) {
      console.error(error)
    }
  };

  getBrowserSummary = async (shortUrlIds, startDate, endDate) => {
    const payload = {
      shortUrlIds: shortUrlIds,
      startDate: startDate,
      endDate: endDate,
    };
    try {
      const res = await getBrowserSummary(payload);
      const totalClicks = res?.reduce((acc, item) => acc + item.value, 0);
      const browserData = res?.map(item => ({
        name: item.key,
        value: item.value,
        percentage: ((item.value / totalClicks) * 100).toFixed(2),
      }));
      this.setState({ browserData });
    } catch (error) {
      console.error(error)
    }
  };

  handleFilterChange = (filterOn) => {
    const lids = filterOn.lids;
    const urlIds = filterOn.urlIds;
    const platform = filterOn.platform;

    this.getAllUrls(lids, urlIds, platform);
  };

  /* URL Change Handler */
  handleUrlChange = (data) => {
    const urlIds = data.urlIds;
    const startDate = data.startDate;
    const endDate = data.endDate;
    this.setState({
      selectedUrls: urlIds,
      startDate: startDate,
      endDate: endDate,
    });
    this.getUrlData(urlIds, startDate, endDate, this.state.selectedUrlId);
  };

  /* Download Data */
  downloadData = (event) => {
    const downloadAs = event.target.value;
    const today = new Date();
    const filename = "clicks_analysis-" + today.getDate() + (today.getMonth() + 1) + today.getFullYear() + "." + downloadAs;
    let input = document.getElementById("link-analysis");
    if (downloadAs === "pdf") {
      const w = input.offsetWidth;
      const h = input.offsetHeight;
      html2canvas(input).then((canvas) => {
        const img = canvas.toDataURL("image/jpeg", 1);
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const doc = new jsPDF("p", "px", [w, h]);
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (imgHeight * pdfWidth) / imgWidth;
        doc.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
        doc.save(filename);
      });
    } else if (downloadAs === "jpeg") {
      html2canvas(input).then((canvas) => {
        const a = document.createElement("a");
        a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        a.download = filename;
        a.click();
      });
    } else if (downloadAs === "png") {
      html2canvas(input, { allowTaint: false, useCORS: true }).then((canvas) => {
        const a = document.createElement("a");
        a.href = canvas.toDataURL("image/png");
        a.download = filename;
        a.click();
      });
    }
  };

  /* Send XLSX */
  sendXlsx = async () => {
    const email = this.props.user.attributes.email;
    const payload = {
      ShortUrlIds: this.state.selectedUrls,
      startDate: moment(this.state.startDate).format("YYYY-MM-DD"),
      endDate: moment(this.state.endDate).format("YYYY-MM-DD"),
      email: email,
    };
    try {
      const res = await sendXlsxReport(payload);
      if (res === "success") {
        this.setState({
          notification: true,
          type: "success",
          message: `File sent to ${email}!`,
        });
      } else {
        this.setState({
          notification: true,
          type: "error",
          message: res.message || "Failed to send XLSX.",
        });
      }
    } catch (error) {
      console.error(error)
    }
  };

  /* Close Notification */
  closeNotification = () => {
    this.setState({ notification: false });
  };

  render() {
    const tabArray2 = [
      {
        label: "Metrics",
        id: "Metrics-tab-0",
        to: `/campaigns/linkAnalysis?id=${this.state.campId}`,
        component: Link,
        icon: <Analytics />,
        iconPosition: "start"
      },
      {
        label: "Manage Destination URLs",
        id: "manage-destination-tab-0",
        to: `/campaigns/linkAnalysis/urls?id=${this.state.campId}`,
        component: Link,
        icon: <ManageAccounts />,
        iconPosition: "start"
      },
    ];

    return (
      <>
        <CampaignActions name={this.state.campaignName} id={this.state.campId} />
        <br />
        <CustomTabs tabArray={tabArray} selectedTab={"4"} />

        <div id="link-analysis-component">
          <Box m={3} sx={{ paddingBottom: "35px", marginTop: "0px" }}>
            <CustomTabs tabArray={tabArray2} selectedTab={"0"} />
          </Box>
          <Stack direction="row" justifyContent="space-between">
            <Box></Box>
            <Box>
              <FormControl size="small" sx={{ marginRight: "70px" }}>
                <InputLabel id="export_as-select-label">Export As</InputLabel>
                <Select
                  sx={{ minWidth: "8vw" }}
                  style={{ color: "black" }}
                  labelId="export_as-select-label"
                  id="export_as-select"
                  label="Export As"
                  value={""}
                  onChange={this.downloadData}
                  variant="outlined"
                  autoWidth
                >
                  <MenuItem value={"png"}>PNG</MenuItem>
                  <MenuItem value={"jpeg"}>JPEG</MenuItem>
                  <MenuItem value={"pdf"}>PDF</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
          <br />
          {/* Filter Box */}
          <LinkFilter campId={this.state.campId} handleFilterChange={this.handleFilterChange} />

          {/* URL - Name and New Addition */}
          {this.state.showLinksFilter && (
            <LinkUrl
              urls={this.state.urls}
              handleUrlChange={this.handleUrlChange}
              start={this.state.campaignStart}
              end={this.state.campaignEnd}
            />
          )}

          {/* Graphs and Charts */}
          <div id="link-analysis">
            <Grid container spacing={2} columns={{ xs: 4, md: 12 }}>
              {/* Total Clicks */}
              <Grid item xs={4} md={12}>
                {this.state.totalClicksData_daily && (
                  <LinkAnalysisChart
                    isToggle={true}
                    data={this.state.totalClicksData_daily}
                    additionalData={this.state.totalClicksData_cumulative}
                    styles={this.state.defaultStyles}
                    chartTitle={"Total Clicks"}
                  />
                )}
              </Grid>

              {/* Devices */}
              <Grid item xs={4} md={6}>
                {this.state.devicesData && (
                  <LinkAnalysisChart
                    isToggle={false}
                    data={this.state.devicesData}
                    styles={this.state.defaultStyles}
                    chartTitle={"Devices"}
                    chartType={"doughnut"}
                    chartOptions={{
                      legend: { display: true, position: "right" },
                    }}
                  />
                )}
              </Grid>

              {/* Referrers */}
              <Grid item xs={4} md={6}>
                {this.state.referrersData && (
                  <LinkAnalysisChart
                    isToggle={false}
                    data={this.state.referrersData}
                    styles={this.state.defaultStyles}
                    chartTitle={"Referrers"}
                    chartOptions={{ indexAxis: "y" }}
                  />
                )}
              </Grid>

              {/* Operating Systems as a Chart */}
              <Grid item xs={4} md={6}>
                {this.state.osData && (
                  <LinkAnalysisChart
                    isToggle={false}
                    data={this.state.osData}
                    styles={this.state.defaultStyles}
                    chartTitle={"Operating System"}
                    chartType={"doughnut"}
                    chartOptions={{
                      legend: { display: true, position: "right" },
                    }}
                  />
                )}
              </Grid>

              {/* Top Browsers as a Table */}
              <Grid item xs={4} md={6}>
                {this.state.browserData && (
                  <Card
                    sx={{
                      padding: 2,
                      height: "400px",
                      display: "flex",
                      flexDirection: "column",
                      marginTop: '20px'
                    }}
                  >
                    <Typography variant="h4" sx={{ color: '#33CC99', marginBottom: 1 }}>
                      Top Browsers
                    </Typography>

                    <TableContainer sx={{ flexGrow: 1 }}>
                      <Table stickyHeader size="small" aria-label="Top Browsers Table">
                        <TableHead>
                          <TableRow>
                            <TableCell><b>Browser</b></TableCell>
                            <TableCell align="right"><b>Percentage</b></TableCell>
                            <TableCell align="right"><b>Clicks</b></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.browserData.map((row, index) => (
                            <TableRow key={index} hover>
                              <TableCell>{row.name}</TableCell>
                              <TableCell align="right">{row.percentage}%</TableCell>
                              <TableCell align="right">{row.value}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                  </Card>
                )}
              </Grid>

              {/* Top Countries and Top Cities Side by Side */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TopCountries
                      data={this.state.countryLocationDataForTable}
                      backgroundColor="#f5f5f5"
                    />
                  </Grid>

                  {/* Top Cities */}
                  <Grid item xs={12} md={6}>
                    <TopCities
                      data={this.state.cityLocationDataForTable}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Data Table */}
              <Grid item xs={4} md={12}>
                <MyPaper sx={{ marginTop: "10px" }}>
                  <Stack direction="row" justifyContent="end" spacing={2} sx={{ marginBottom: "10px" }}>
                    <Typography variant="h4" sx={this.state.defaultStyles.typography}>
                      Data Table
                    </Typography>
                    <Button variant="contained" startIcon={<Download />} sx={{ height: "40px" }} onClick={this.sendXlsx}>
                      Download .xlsx
                    </Button>
                  </Stack>
                  <Datatables
                    data={this.state.linkInfo_tableData}
                    columns={this.state.linkInfo_tableColumns}
                    options={this.state.linkInfo_tableOptions}
                  />
                </MyPaper>
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Notification Snackbar */}
        <Snackbar
          open={this.state.notification}
          autoHideDuration={3000}
          onClose={this.closeNotification}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={this.closeNotification} severity={this.state.type} variant="filled" sx={{ width: "100%" }}>
            {this.state.message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

LinkAnalysis.propTypes = {
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const user = state.user;
  return { user };
}

export default connect(mapStateToProps)(LinkAnalysis);
