import axios from "axios";
import { API_HOST } from "../../config/main.json";

const optionAxios = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};


const getCampaignData = (campaignId) => {
  return axios.get(`${API_HOST}/campaigns/${campaignId}`, optionAxios)
    .then(response => response.data)
    .catch(error => {
      console.error(error);
    });
};

const filterLinks = (payload) => {
  return axios.post(`${API_HOST}/link/filter`, payload, optionAxios)
    .then(response => response.data)
    .catch(error => {
      throw error.response?.data || { message: "An error occurred while filtering links." };
    });
};

const getTotalClicksSummary = (payload) => {
  return axios.post(`${API_HOST}/link/analysis/clicks/summary`, payload, optionAxios)
    .then(response => response.data)
    .catch(error => {
      throw error.response?.data || { message: "An error occurred while fetching total clicks data." };
    });
};

const getDevicesData = (payload) => {
  return axios.post(`${API_HOST}/link/analysis/devices`, payload, optionAxios)
    .then(response => response.data)
    .catch(error => {
      throw error.response?.data || { message: "An error occurred while fetching devices data." };
    });
};

const getReferrersData = (payload) => {
  return axios.post(`${API_HOST}/link/analysis/referrers`, payload, optionAxios)
    .then(response => response.data)
    .catch(error => {
      throw error.response?.data || { message: "An error occurred while fetching referrers data." };
    });
};

const getCountryLocationData = (payload) => {
  return axios.post(`${API_HOST}/link/analysis/countries`, payload, optionAxios)
    .then(response => response.data)
    .catch(error => {
      throw error.response?.data || { message: "An error occurred while fetching country location data." };
    });
};

const getCityLocationData = (payload) => {
  return axios.post(`${API_HOST}/link/analysis/cities`, payload, optionAxios)
    .then(response => response.data)
    .catch(error => {
      throw error.response?.data || { message: "An error occurred while fetching city location data." };
    });
};


const getLinkInfo = (payload) => {
  return axios.post(`${API_HOST}/link/analysis/basic`, payload, optionAxios)
    .then(response => response.data)
    .catch(error => {
      throw error.response?.data
    });
};


const getOSSummary = (payload) => {
  return axios.post(`${API_HOST}/link/analysis/os`, payload, optionAxios)
    .then(response => response.data)
    .catch(error => {
      throw error.response?.data || { message: "An error occurred while fetching OS summary." };
    });
};


const getBrowserSummary = (payload) => {
  return axios.post(`${API_HOST}/link/analysis/browser`, payload, optionAxios)
    .then(response => response.data)
    .catch(error => {
      throw error.response?.data || { message: "An error occurred while fetching Browser summary." };
    });
};


const sendXlsxReport = (payload) => {
  return axios.post(`${API_HOST}/link/analysis/sendxlsx`, payload, optionAxios)
    .then(response => response.data)
    .catch(error => {
      throw error.response?.data || { message: "An error occurred while sending XLSX report." };
    });
};

export {
    getCampaignData,
    filterLinks,
    getTotalClicksSummary,
    getDevicesData,
    getReferrersData,
    getCountryLocationData,
    getCityLocationData,
    getLinkInfo,
    getOSSummary,
    getBrowserSummary,
    sendXlsxReport
    
}